<template>
  <router-view></router-view>
</template>

<script>
import '@fullcalendar/core/vdom';

export default {
  name: 'app'
};
</script>


<style lang="scss">

$fa-font-path: '../node_modules/font-awesome/fonts';

@import '../node_modules/bootstrap/scss/bootstrap.scss';
@import '../node_modules/font-awesome/scss/font-awesome.scss';
</style>
