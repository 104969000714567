import Vue from 'vue';

import App from './App.vue';
import router from './router';
import filters from './filters';
import firebase from './firebase';

Vue.config.productionTip = false;

Vue.filter('role', filters.role);
Vue.filter('date', filters.date);

let app;

firebase.auth().onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      router,
      render: h => h(App)
    }).$mount('#app');
  }
});
